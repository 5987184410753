// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCZXWuhj6dRd8aj6Y4LyshGlu5U-LYaR58",
  authDomain: "bcard-387ab.firebaseapp.com",
  projectId: "bcard-387ab",
  storageBucket: "bcard-387ab.appspot.com",
  messagingSenderId: "822184520765",
  appId: "1:822184520765:web:8612c800ccf6ce5bdbce54"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// connect firestore to emulator (if running locally)
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
const db = getFirestore(app);
if (window.location.hostname === "localhost") {
    connectFirestoreEmulator(db, process.env.VUE_APP_FIREBASE_ADDRESS, 8080);
    connectStorageEmulator(getStorage(app), process.env.VUE_APP_FIREBASE_ADDRESS, 9199);
    connectAuthEmulator(getAuth(app), `http://${process.env.VUE_APP_FIREBASE_ADDRESS}:9099`);
    connectFunctionsEmulator(getFunctions(app), process.env.VUE_APP_FIREBASE_ADDRESS, 5001);
}

export default app;