import firebaseApp from '../firebase/firebaseApp';
import { getIdTokenResult } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions(firebaseApp)
const usersList = httpsCallable(functions, 'users-index')


export default {
  namespaced: true,
  state: {
    users: [],
    user: null,
    userToken: null,
  },
  getters: {
    pageUser: ({users}) => (page) => {
      return users.find( ({email}) => email == page.email )
    },
    isAdmin(state) {
      if( state.userToken == null ) return false
      if( state.userToken.claims == null ) return false
      return state.userToken.claims.role == 'admin'
    },
  },
  mutations: {
    async setUsers(state, users) {
      state.users = users
    },
    async setUser(state, user) {
      state.user = user;
      if( user != null ) {
        state.userToken = await getIdTokenResult(user)
      }
    },
  },
  actions: {
    async fetch({commit}) {
      commit('setUsers', (await usersList()).data)
    }
  },
}
