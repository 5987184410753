import { createStore } from 'vuex'
import firebaseApp from '../firebase/firebaseApp';
import { getFirestore, collection, getDocs, getDoc, doc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

import users from './users'

export default createStore({
  state: {
    pages: {},
    pageImages: {},
  },
  getters: {
    allPages(state) {
      return state.pages;
    },
    orderedIds(state) {
      const pages = state.pages;
      const sortedIds = Object.keys(pages).sort((a, b) => {
        return pages[a].name.localeCompare(pages[b].name);
      });
      return sortedIds;
    },
    pageById: (state) => (pageId) => {
      state.pages[pageId]
    },
    pageByEmail: ({ pages }) => (email) => {
      for (let id in pages) {
        if (pages[id].email == email) {
          return { id: id, page: pages[id] }
        }
      }
      return null
    },
    pageImage: (state) => (pageId) => {
      if (!state.pageImages[pageId]) {
        state.pageImages[pageId] = {};
      }
      return state.pageImages[pageId];
    },
    pageBy: (state) => (sortBy) => {
      return Object.values(state.pages).sort((a, b) => {
        return (new String(a[sortBy])).localeCompare(new String(b[sortBy]))
      })
    }
  },
  mutations: {
    setPages(state, pages) {
      state.pages = pages;
    },
    setPage(state, doc) {
      state.pages[doc.id] = doc.data();
    },
    setPageImage(state, { pageId, url }) {
      if (!state.pageImages[pageId]) {
        state.pageImages[pageId] = {};
      }
      state.pageImages[pageId] = url;
    },
    deleteUser(state, pageId) {
      const index = state.pages.findIndex((page) => page.id == pageId)
      state.pages.splice(index, 1)
    },
},
  actions: {
  async fetchPages({ commit, dispatch, getters, state }) {
    const isAdmin = getters['users/isAdmin']
    let pagesRef = collection(db, "pages");
    const user = state.users.user
    if (!isAdmin && user != null) {
      pagesRef = collection(db, `users/${user.uid}/pages`)
    }
    const snapshot = await getDocs(pagesRef);
    snapshot.forEach((document) => {
      let data = document.data()
      commit('setPage', document)
      if (data.display) {
        dispatch("fetchPage", document.id)
      }
      dispatch('fetchPageImage', document.id);
    });
  },
  async fetchPage({ commit, dispatch }, pageId) {
    const pageRef = doc(db, "pages", pageId);
    const docSnap = await getDoc(pageRef);
    if (!docSnap.exists()) {
      console.log("No such document!");
      return
    }
    commit('setPage', docSnap);
    dispatch('fetchPageImage', pageId);
  },
  async fetchPageImage({ commit }, pageId) {
    const storageRef = ref(storage, `pages/${pageId}`);
    const url = await getDownloadURL(storageRef);
    commit('setPageImage', { pageId, url });
  }
},
  modules: {
  users,
}
})
