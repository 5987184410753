<template>
  <div class="management">
    <!-- <div class="management" v-if="isAdmin"> -->
    <h1>Pages</h1>
    <p>Here you can manage your pages</p>

    <div class="pages">
      <input type="text" placeholder="Search" v-model="searchTerm">
      <div class="page" v-for="key in orderedIds" :key="key">
        <router-link :to="{ name: 'Page', params: { pageId: key } }" class="page-link"
          v-show="filteredPages != null && filteredPages.includes(key)">
          <img :src="pageImages[key]" alt="" v-if="pageImages[key]">
          <span v-text="pages[key].name" class="name"></span>
          <span class="id">/{{ key }}</span>
          <span v-text="pages[key].email" class="email"></span>
        </router-link>
      </div>
      <button class="create-button"><router-link :to="{ name: 'NewPage' }">Create Page</router-link></button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ManagementPanel',
  data() {
    return {
      searchTerm: '',
    }
  },
  methods: {},
  computed: {
    isAdmin() {
      return this.$store.getters['users/isAdmin']
    },
    pages() {
      return this.$store.state.pages
    },
    pageImages() {
      return this.$store.state.pageImages
    },
    orderedIds() {
      return this.$store.getters.orderedIds
    },
    filteredPages() {
      return Object.keys(this.pages).filter(key => {
        const page = this.pages[key]
        return page.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      })
    }
  },
  async mounted() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.management {

  h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 30px;

    font-size: 1.2em;
  }

  .pages {
    margin-top: 30px;
    

    input {
      width: 100%;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      margin-bottom: 10px;

      font-size: 1em;
    }

    .page {
      &-link {
        display: grid;
        align-items: center;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-bottom: 10px;
        transition: all 0.2s ease-in-out;
        gap: 0 10px;
        grid-template-columns: 50px min-content 1fr;
        text-decoration: none;
        color: #ffffff;
        grid-template-areas:
          'image name name'
          'image id email ';

        &:hover {
          background: #f0f0f0;
          color: #000;

          box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
        }

        &:active {
          background: #e0e0e0;
        }

        img {
          grid-area: image;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 10px;
          object-fit: cover;
        }

        .name {
          grid-area: name;
          font-weight: bold;
          margin-left: 5%;

          font-size: 1.2em;
        }

        .id,
        .email {
          color: #aaa;
          font-size: 0.8em;
        }

        .id {
          grid-area: id;

          color: #888;
          font-size: 0.9em;
        }

        .email {
          grid-area: email;
        }
      }
    }

    .create-button {
      display: inline-block;
      padding: 10px 20px;
      background-color: #42b983;
      color: #fff;
      text-decoration: none;
      border-radius: 5px;
      text-align: center;
      transition: background-color 0.3s ease;
      margin-top: 20px;
      cursor: pointer;

      &:hover {
        background-color: #ffffff;
        border: 1px solid #42b983;
        color: #42b983;
      }
    }
  }
}
</style>
