<template>
  <div class="links">
    <router-link :to="{ name: 'Home' }">Enter</router-link>
    <router-link :to="{ name: 'Register' }">Register</router-link>
    <router-link :to="{ name: 'Reset' }">Recover Password</router-link>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'AuthLinks',
  data() {
    return {
    };
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.links {
  display: flex;
  gap: 30px;
  justify-content: center;
}
</style>