<template>
  <div class="login">
    <h1 class="title">Enter</h1>
    <form @submit.prevent="login">
      <label for="email">Email</label>
      <input type="text" v-model="email" id="email">
      <label for="password">Password</label>
      <input type="password" v-model="password" id="password">
      <button type="submit">Enter</button>
      <AuthLinks/>
    </form>
  </div>
</template>

<script>
// @ is an alias to /src
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import firebaseApp from '@/firebase/firebaseApp';
const auth = getAuth(firebaseApp);

import AuthLinks from '@/components/AuthLinks.vue';

export default {
  name: 'LoginView',
  components: {
    AuthLinks,
  },
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    async login() {
      console.log('login');
      try {
        const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
        userCredential.user;
      } catch (error) {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  flex-direction: column;
  padding: 30px;
  max-width: 400px;
  width: calc(100% - 60px);
  margin: auto;
  box-shadow: 0 0 10px #888;
  border-radius: 10px;
  background-color: #444;
  .title {
    text-align: center;
    margin: 0 0 30px;
    text-transform: uppercase;
    font-size: 1.5em;
    font-weight: bold;
  }
  label {
    display: block;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;
    padding: 5px 0;
    margin: 0;
  }
  input {
    &[type="text"],
    &[type="password"] {
      width: 100%;
      padding: 10px;
      margin-bottom: 20px;
      font-size: 16px;
      border-radius: 5px;
      border: 1px solid #ccc;
      background-color: #555;
      color: #fff;
    }
  }
  button {
    margin-top: 10px;
    text-transform: uppercase;
    font-weight: bold;
    transition: 0.2s ease-in-out;
    transition-property: background-color, color;
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    background-color: #ddd;
    color: #333;

    &:hover {
      background-color: #ccc;
    }
  }
}
</style>