<template>
  <nav v-if="user">
    <router-link :to="{name: 'Home'}" >Home</router-link>
    <router-link :to="{name: 'Users'}" v-if="isAdmin">Users</router-link>
    <a href="#" @click.prevent="logout" class="logout">Exit</a>
  </nav>
  <Login v-if="showLogin"/>
  <router-view class="main-view" v-if="!showLogin"/>
</template>

<script>
import firebaseApp from '@/firebase/firebaseApp';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getFunctions, httpsCallable } from '@firebase/functions';
const auth = getAuth(firebaseApp);

import Login from '@/components/Login.vue';

export default {
  name: 'app',
  components: {
    Login,
  },
  data() {
    return {
    }
  },
  methods: {
    async logout() {
      try {
        await signOut(auth);
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    showLogin() {
      let routesWhiteList = ['Register', 'Reset'];
      return this.user == null && !routesWhiteList.includes(this.$route.name);
    },
    user() {
      return this.$store.state.users.user;
    },
    isAdmin() {
      return this.$store.getters['users/isAdmin']
    },
    token() {
      return this.$store.state.users.userToken
    },
  },
  watch: {
    token() {
      if( this.token ) {
        this.$store.dispatch('fetchPages');
      }
    },
  },
  created() {
    onAuthStateChanged(auth, async (user) => {
      this.$store.commit('users/setUser', user);
      if(user == null) return
      if( user.email.includes('@blatstudio.com') ) {
        const functions = getFunctions(firebaseApp)
        const update = (await httpsCallable(functions, 'users-requestAdmin')()).data
        if( update ) {
          auth.signOut()
        }
      }
    });
  }
}
</script>

<style lang="scss">
.main-view {
  padding: 0 30px 30px;
}
nav {
  display: flex;
  gap: 30px;
  padding: 10px 30px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  a {
    font-weight: bold;
    &.router-link-exact-active {
    }
    &.logout {
      color: #e74c3c;
      margin: auto 0 auto auto;
    }
  }
}
.main-view {
  overflow: auto;
  max-height: 100%;
}
</style>
