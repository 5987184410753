<template>
  <div class="home">
    <ManagementPanel/>
    <!-- <PageEditForm v-else-if="this.page" :page-id="this.page.id" :changeEmail="false"/> -->
  </div>
</template>

<script>
import ManagementPanel from '@/components/ManagementPanel.vue'
// import PageEditForm from '@/components/page/EditForm.vue'

export default {
  name: 'HomeView',
  components: {
    ManagementPanel,
    // PageEditForm
  },
  data() {
    return {}
  },
  computed: {
    isAdmin() {
      return this.$store.getters['users/isAdmin']
    },
    user() {
      return this.$store.state.users.user
    },
    page() {
      return this.$store.getters['pageByEmail'](this.user.email)
    },
  },
  mounted() {
  }
}
</script>
