import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '../views/UsersView.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/RegisterView.vue'),
    props: route => ({
      autoFilledEmail:  route.query.email
    }),
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import(/* webpackChunkName: "reset" */ '../views/ResetView.vue'),
    props: route => ({
      code: route.query.code != null ? route.query.code : ''
    }),
  },
  {
    path: '/pages/new',
    name: 'NewPage',
    component: () => import(/* webpackChunkName: "page" */ '../views/PageView.vue'),
  },
  {
    path: '/:pageId',
    name: 'Page',
    component: () => import(/* webpackChunkName: "page" */ '../views/PageView.vue'),
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
