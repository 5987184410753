import 'normalize.css'
import './assets/scss/main.scss'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import firebaseApp from '@/firebase/firebaseApp'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

const auth = getAuth(firebaseApp)
var appInitialized = false

onAuthStateChanged(auth, () => {
    if( !appInitialized ) {
        createApp(App).use(store).use(router).mount('#app')
        appInitialized = true
    }
})

